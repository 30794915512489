import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Builders = () => {
    return (
        <Layout>
             <Seo
      title="Urbanizaciones - BIKESAFE"
      description="Los productos compactos y de excelente relación calidad-precio de Bike Safe significan que el estacionamiento para bicicletas ya no supondrá un quebradero de cabeza para los promotores inmobiliarios. Hable con uno de nuestros representantes, que le podrá recomendar la solución más adecuada para usted."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Urbanizaciones
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Los productos compactos y de excelente relación calidad-precio de Bike Safe significan que el estacionamiento para bicicletas ya no supondrá un quebradero de cabeza para los promotores inmobiliarios. Hable con uno de nuestros representantes, que le podrá recomendar la solución más adecuada para usted.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bikestand.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"estamos plenamente satisfechos con su servicio. ¡Algo que resulta muy alentador en estos tiempos difíciles!"
                                <strong> James Whiteley (comprador) Sir Robert Mcalpine.</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Builders;